<template>
  <div class="bg-primary-1-100 text-white px-5 h-10 flex items-center">
    <div class="container mx-auto w-full text-tiny flex justify-center md:justify-between">
      <p class="font-normal" :class="[$i18n.locale === 'en' ? 'font-display' : 'font-display-ar']">
        {{ $t('notice') }}
      </p>

      <div class="hidden md:flex items-center text-base">
        <AppLink to="/contact">
          {{ $t('customerSupport') }}
        </AppLink>
        <a
          class="ml-10 text-base"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold -mt-2' : 'font-body']"
          :href="switchLocalePathWithParams($i18n.locale === 'en' ? 'ar' : 'en')"
          >{{ $t('switchLang') }}</a
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
const switchLocalePath = useSwitchLocalePath();
const route = useRoute();

// To keep query params if exists
function switchLocalePathWithParams(locale: string) {
  const queryParams = route.fullPath.split('?')[1] ? `?${route.fullPath.split('?')[1]}` : '';
  return `${switchLocalePath(locale)}${queryParams}`;
}
</script>

<i18n>
{
  "en": {
    "loginOrRegister": "Login or Register",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "notice": "FREE shipping on orders above 10,000 EGP",
    "customerSupport": "Customer Support",
    "greet": "Hello, {name}"
  },
  "ar": {
    "contactUs": "اتصل بنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "notice": "شحن مجاني للطلبات التي تتخطى ١٠,٠٠٠ جنيه",
    "customerSupport": "دعم العملاء",
    "loginOrRegister": "تسجيل الدخول",
    "greet": "مرحبا، {name}"
  }
}
</i18n>
